import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PageTitle from "../components/PageTitle";
import BlockContentImage from "../components/BlockContentImage";

const AboutPage = ({ data }) => {
  let members = [];
  data.members.edges.forEach((member) => {
    switch (member.node.id) {
      case "54b840b8-2bcb-5435-9356-bc0df79c9fe3": // Alka Bhave
        members.splice(0, 0, member);
        break;
      case "6e41f3ff-f6a5-593f-a578-6648422fef42": // Bhaskar Bhave
        members.splice(1, 0, member);
        break;
      default:
        members.push(member);
    }
  });

  return (
    <Layout>
      <SEO title="About" keywords={[`health`, `fitness`, `members`, `about`]} />
      <PageTitle image={data.settings.headerImage.asset.fixed} title="About" />
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        <section>
          <h1 className="text-heading">Members</h1>
          <div>
            {members.map((member, i) => (
              <div key={i} className="flex flex-wrap mb-12 w-full">
                <div className="lg:w-1/3 w-full lg:pr-3 lg:mb-0 mb-3">
                  {member.node.image ? (
                    <Img
                      fluid={member.node.image.asset.fluid}
                      alt={member.node.name}
                      className="lg:w-full w-2/3 border border-gray-600 rounded"
                    />
                  ) : (
                    <div
                      className="lg:w-full w-2/3 border bg-gray-300 border-gray-600 rounded flex items-center justify-center py-10"
                      style={{
                        maxWidth: "500px",
                        maxHeight: "500px",
                      }}
                    >
                      <i className="fa fa-user text-200px text-gray-600"></i>
                    </div>
                  )}
                </div>
                <div className="lg:w-2/3 w-full lg:pl-3">
                  <h2 className="mb-1 font-serif text-3xl font-bold">
                    {member.node.name}
                  </h2>
                  <BlockContent
                    blocks={member.node._rawBio || []}
                    serializers={{
                      types: {
                        captionedImage: BlockContentImage,
                      },
                    }}
                    projectId={process.env.GATSBY_SANITY_ID}
                    dataset={process.env.GATSBY_SANITY_DATASET}
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderAbout {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    members: allSanityAuthor {
      edges {
        node {
          _rawBio
          id
          name
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
